<template>
    <div class="home">
      <div style="
            height: 20px;
            line-height: 60px;
            right: 42px;
    position: fixed;
            text-align: right;">
            <i @click="handleSelect('/usermanagement/usermanagementlist')" class="el-icon-close"></i>
      </div>
        <el-form :inline="true" :model="formInline" class="demo-form-inline topm">
            <el-form-item :required="true" label="手机号:">
                 <el-input  v-model="formInline.phone"></el-input>
            </el-form-item>
            <el-form-item :required="true" label="手机号所在地:">
              <el-select v-model="formInline.area_name" @change='areacodename' filterable placeholder="请选择">
                <el-option
                  v-for="(item,index) in options"
                  :key="index"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
             <el-form-item :required="true" label="区号:">
                 <el-input disabled v-model="formInline.area_code"></el-input>
            </el-form-item><br> 
            <el-form-item :required="true" label="密码:">
                 <el-input  v-model="formInline.password" show-password></el-input>
                  <div style="position: absolute;top:0;right:-305px;width:300px;color: red;font-family: fangsong;">包含大、小写英文字母+数字，最少8位</div>
            </el-form-item><br>
            <el-form-item :required="true" label="性别:">
                <el-radio v-model="formInline.sex" :label="1">男</el-radio>
                <el-radio v-model="formInline.sex" :label="2">女</el-radio>
            </el-form-item><br>
            <el-form-item :required="true" label="姓名:">
                 <el-input  v-model="formInline.name"></el-input>
            </el-form-item><br>
            <el-form-item :required="true" label="昵称:">
                 <el-input  v-model="formInline.nickname"></el-input>
            </el-form-item><br>
            <el-form-item :required="true" label="邮箱:">
                 <el-input style="width:300px;" v-model="formInline.email"></el-input>
            </el-form-item><br>
            <el-form-item :required="true" label="头像:">
              <div class="block"  v-for="(img,index) in list" :key="img.id">
               <i @click.stop="deleimage(index)" class="el-icon-close imgii"></i>
                    <el-image
                    style="width: 150px; height: 150px"
                    :src="img.src"
                    fit="fill">
                    </el-image>
              </div>
               <input ref="filElem" type="file" name='file[]' hidden class="upload-file" @change="getFile" multiple>
            <div v-if="list.length == 0"  @click="choiceImg" class="el-upload el-upload--picture-card">
                <i  style="margin-top: 55px;margin-left: 10px;" class="el-icon-plus"></i>
            </div>
            </el-form-item><br>
            <el-form-item :required="true" label="个人简介:">
                <el-input
                type="textarea"
                    maxlength="1000"
                    show-word-limit
                    style="width:800px;"
                     :autosize="{ minRows: 4, maxRows: 8}"
                placeholder="请输入内容"
                v-model="formInline.description">
                </el-input>
            </el-form-item>
        <div class="pt20"></div>
            <el-form-item>
                <el-button type="primary" style="margin-left:300px" @click="onSubmit()">提交</el-button>
            </el-form-item>
        </el-form>

      <div class="pt20"></div>

    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import config from '../../lib/config'

export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
      formInline: {
          phone:'',
          area_code:'1',
          password:'',
          sex:1,
          nickname:'',
          name:'',
          headimg:'',
          area_name:'美国',
          email:'',
          description:'',
      },
      list:[],
      options:[],   
      timevalue:'',
      loading: false,
    }
  },
  methods: {
    handleSelect(url){
        this.$router.push(url)
    },
    areacodename(e){
      //console.log(e);
      this.formInline.area_code = e;
    },
      onSubmit(){
        let _this = this;
        if(_this.formInline.phone === ''  || _this.formInline.area_code === ''  || _this.formInline.password === ''  || 
        _this.formInline.nickname === ''  || _this.formInline.name === ''  || _this.formInline.area_name === ''  || 
        _this.formInline.email === ''  || _this.formInline.description === '' || _this.list.length == 0){
                _this.$message.error('请填写必填项');return;
        }
        
        var boo = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/.test(_this.formInline.password);
        if(!boo){
            _this.$message.error('密码至少包含大写字母，小写字母，数字，且不少于8位!');
            return
        }
        let formdata = new FormData();
        formdata.append("file", _this.list[0].file);
         axios.post(config.uploadHeadimg,formdata,{headers:{'Content-Type':'multipart/form-data'}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.formInline.headimg = response.data.data;
                        axios.post(config.consumerAdd,_this.formInline)
                          .then(function (response) {
                              if(response.data.code == 200){
                                _this.$message({
                                  message: response.data.message,
                                  type: 'success'
                                });
                                _this.goNav('/usermanagement/usermanagementlist');
                              }else{
                                  _this.$message.error(response.data.message);
                              }
                            })
                          .catch(function (error) {
                            console.log(error);
                          });
                }else{
                _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
        
      },
    deleimage(index){
      this.list.splice(index, 1);
    },
    choiceImg(){
        if(this.list.length>1){
            return;
        }
         this.$refs.filElem.dispatchEvent(new MouseEvent('click')) 
    },
    getFile(){
            var that = this;
            let files = this.$refs.filElem.files;
            for(let i in files){
              if(i == 'length'){
                return;
              }else{
                if(this.list.length+i*1+1> 6){
                  return
                }
              }
              let inputFile = files[i];
           // console.log(inputFile)
          //  const inputFile = this.$refs.filElem.files[0];
            if(inputFile){
                if(inputFile.type !== 'image/jpeg' && inputFile.type !== 'image/png' && inputFile.type !== 'image/gif'){
                  console.log('上传文件中有格式不对');
                    return;
                }
                this.imgInfo = Object.assign({}, this.imgInfo, {
                    name: inputFile.name,
                    size: inputFile.size,
                    lastModifiedDate: inputFile.lastModifiedDate.toLocaleString()
                })
                const reader = new FileReader();
                reader.readAsDataURL(inputFile);
                reader.onload = function () {
                    let id = new Date().getTime();
                    let imgSrc = this.result;
                    that.list.push({
                        id:id,
                        src:imgSrc,
                        file:inputFile,
                    });
                }
            } else {
                return;
            }
            }
        },
      goNav(url){// 
        this.$router.push(url)
      },
      contryNumberSelect(){
        let _this = this;
        axios.get('https://minip.fedup.cn/api/common/contryNumberSelect',{params:{}})
        .then(function (response) {
            if(response.data.code == 200){
              let list = response.data.data;
              let arr = [];
              for(let i in list){
                for(let j in list[i].countrys){
                  arr.push({
                    value:list[i].countrys[j].number,
                    label:list[i].countrys[j].name,
                  })
                }
              }
              _this.options = arr;
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      },
  },
  created(){
    this.contryNumberSelect();
  }
}
</script>
<style scoped>
.left{
    width: 335px;
}
.el-icon-close:before{
  font-size: 24px;
}
.topm{
  margin-top: 20px;
}
.imgii{
  position: absolute;
  right: 0;
  z-index: 999;
  font-size: 24px;
}
.block{
  position: relative;
}
</style>